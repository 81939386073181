import {Injectable} from '@angular/core';
import {User} from "../user-list/types/user.model";

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  authToken = localStorage.getItem('pbAuthToken') || undefined;
  user?: User;
  language = 'xx';

  constructor() {
  }

  setAuthToken(token: string | undefined) {
    this.authToken = token;
    if (token) {
      localStorage.setItem('pbAuthToken', token);
    } else {
      localStorage.removeItem('pbAuthToken');
    }
  }

  isManager() {
    return this.user?.roles.includes("MANAGER") || this.isAdmin();
  }

  isAdmin() {
    return this.user?.roles.includes("ADMIN");
  }
}
